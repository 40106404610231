import React, { useEffect, useState, useCallback } from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import FullMapContainer from '../components/UI/Containers/FullMapContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'
import DataEm from '../components/UI/Data/DataEm/DataEm'
import locations from './../data/locations.json'
import { Icon } from '@iconify/react'

import './../styles/pageSpecific/contact.scss'
import OfficeMap from '../components/UI/Images/USMap/OfficeMap'
import LeafletMap from '../components/UI/LeafletMap/LeafletMap'

const ContactUsPage = () => {
  const [location, setLocation] = useState({
    name: 'MD',
    cordinates: [38.279523, -76.467889],
    phone: '301-737-7100',
    address1: '22290 Exploration Dr.',
    address2: 'Lexington Park, MD 20653',
  })
  const [hideOfficeList, setHideOfficeList] = useState(true)
  const [isDesktop, setIsDesktop] = useState(true)

  const pageInfo = {
    pageType: 'interior',
    title1: 'Contact',
    title2: 'Us',
    breadcrumbs: [''],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  //add hover events to state map
  const onKeyOverHandler = (e) => {
    e.preventDefault()
    let map = document.getElementById('officeMapSVG')
    map?.getElementById(e.target.dataset.state)?.classList.add('highlight')
  }

  //remove hover effects to state map
  const onKeyOutHandler = (e) => {
    e.preventDefault()
    let map = document.getElementById('officeMapSVG')
    map?.getElementById(e.target.dataset.state)?.classList.remove('highlight')
  }

  //toggle office button list
  const toggleOfficeList = () => {
    setHideOfficeList((prevState) => !prevState)
  }

  //collapse office button list
  const closeOfficeList = () => {
    setHideOfficeList(true)
  }

  //sets office thats selected
  const setLocationToSelected = (location) => {
    setLocation({
      name: `${location.Name}`,
      cordinates: [location.Position.lat, location.Position.lng],
      phone: `${location.Phone}`,
      address1: `${location.Address1}`,
      address2: `${location.Address2}`,
    })
    closeOfficeList()
  }

  //sets roadmap location from office button clicked
  const officeClickHandler = (e) => {
    e.preventDefault()
    let location = locations[e.target.dataset.officenumber]
    if (!location) location = locations[0]
    setLocationToSelected(location)
  }

  //sets roadmap from office from clicked state
  const stateMapClickHandler = (e) => {
    e.preventDefault()
    let locationName = e.target.dataset.state || e.target.id
    let locationData = locations.findIndex((location) => {
      return location.Name === locationName.toUpperCase()
    })
    if (!locationData) locationData = 0
    setLocationToSelected(locations[locationData])
    document
      .getElementById('leafletOfficeMap')
      ?.scrollIntoView({ behavior: 'smooth' })
  }

  //generates office location buttons on road map
  let locationButtons = locations.map((loc, idx) => {
    let locationIcon = 'mdi:map-marker'
    if (loc.mainOffice) {
      locationIcon = 'mdi:office-building-marker'
    }
    return (
      <button
        key={loc.Name}
        className={`${loc.Name === location.name ? 'active' : ''}`}
        location={loc}
        data-officenumber={idx}
        onClick={officeClickHandler}
      >
        <Icon icon={locationIcon} inline={true} width={24} />
        {loc.Title}
      </button>
    )
  })

  //sets state to desktop/mobile once checked
  const checkScreenSize = useCallback((mediaCheck) => {
    if (mediaCheck.matches) {
      setIsDesktop(false)
    } else {
      setIsDesktop(true)
    }
  }, [])

  //Checks for screen resize if desktop/mobile
  useEffect(() => {
    let mediaCheck = window.matchMedia('(max-width: 875px)')
    checkScreenSize(mediaCheck)
    mediaCheck.addEventListener('change', checkScreenSize)
    return () => {
      mediaCheck.removeEventListener('change', checkScreenSize)
    }
  }, [checkScreenSize])

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        <SectionContainer
          id=""
          color="white"
          type="contained"
          classes={['narrowContainer']}
        >
          <SectionHeader color="navy" alignment="center" size="med" trim="true">
            We Are <span>Nationwide</span>
          </SectionHeader>
          <section className={`OfficeMap`}>
            <OfficeMap stateClickHandler={stateMapClickHandler} />
            {/* East Coast Offices */}
            <div id="mapCoastCities" className={'mapCoastCities'}>
              <button
                onMouseOver={onKeyOverHandler}
                onFocus={onKeyOverHandler}
                onMouseOut={onKeyOutHandler}
                onBlur={onKeyOutHandler}
                onClick={stateMapClickHandler}
                data-state={'md'}
              >
                <Icon icon="mdi:office-building-marker" inline={true} />
                Patuxent River, MD
              </button>
              <button
                onMouseOver={onKeyOverHandler}
                onFocus={onKeyOverHandler}
                onMouseOut={onKeyOutHandler}
                onBlur={onKeyOutHandler}
                onClick={stateMapClickHandler}
                data-state={'va'}
              >
                <Icon icon="mdi:map-marker" inline={true} />
                Reston, VA
              </button>
              <button
                onMouseOver={onKeyOverHandler}
                onFocus={onKeyOverHandler}
                onMouseOut={onKeyOutHandler}
                onBlur={onKeyOutHandler}
                onClick={stateMapClickHandler}
                data-state={'ri'}
              >
                <Icon icon="mdi:map-marker" inline={true} />
                Middletown, RI
              </button>
              {/* <button
                onMouseOver={onKeyOverHandler}
                onFocus={onKeyOverHandler}
                onMouseOut={onKeyOutHandler}
                onBlur={onKeyOutHandler}
                onClick={stateMapClickHandler}
                data-state={'dc'}
              >
                <Icon icon="mdi:map-marker" inline={true} />
                Washington DC
              </button> */}
            </div>
          </section>
          {/* Map Key */}
          <section className="officeMapKey dataContent">
            <div className="officeMapKey_wrap colorCode offices">
              <DataEm
                total="10"
                title="Offices"
                bg="light"
                size="sm"
                stackText={true}
              />
            </div>
            <div className="officeMapKey_wrap colorCode worksites">
              <DataEm
                total="45"
                title="Worksites"
                bg="light"
                size="sm"
                stackText={true}
              />
            </div>
          </section>
        </SectionContainer>
        <FullMapContainer
          map={
            <LeafletMap
              location={location}
              key={isDesktop}
              isDesktop={isDesktop}
            />
          }
          classes={['contactMap']}
        >
          <SectionHeader
            color="white"
            alignment="left"
            size="med"
            trim={true}
            boldBreak={false}
          >
            Our <span>Offices</span>
          </SectionHeader>
          <section
            className={`contactMap_officeList ${hideOfficeList ? 'hide' : ''}`}
          >
            {locationButtons}
          </section>
          <button
            className="contactMap_toggleOfficeListBtn"
            aria-label="Toggle Office List"
            onClick={toggleOfficeList}
          >
            <Icon icon="eva:arrow-ios-downward-fill" width={24} inline={true} />
          </button>
        </FullMapContainer>
      </div>
    </GeneralPageLayout>
  )
}

export default ContactUsPage
